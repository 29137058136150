import React, { useState } from "react"
import PropTypes from "prop-types"
import Tab from "./Tab"

export default function Tabs(props) {
  const { children } = props
  const [activeTab, setActive] = useState(children[0].props.label)

  function switchPanel(tab) {
    setActive(tab)
  }

  return (
    <div className="tabs">
      <ul className="menu-tabs">
        {children.map(child => {
          const { label } = child.props
          return (
            <Tab
              activeTab={activeTab}
              key={label}
              label={label}
              onClick={switchPanel}
            />
          )
        })}
      </ul>

      <div className="tab-content">
        {children.map(child => {
          if (child.props.label !== activeTab) return undefined
          return child.props.children
        })}
      </div>
    </div>
  )
}

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
}
