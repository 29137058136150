import React from "react"
import Img from "gatsby-image"
import { FormattedMessage } from "gatsby-plugin-intl"

export default function Stories(props) {
  return (
    <section className="stories" id="stories">
      <div className="container">
        <h2>
          <FormattedMessage
            id="stories_section_h2"
            defaultMessage="Příběhy těch, co Živlovku vyzkoušeli"
          />
        </h2>
        <div className="cols-3">
          <div className="col">
            {/* <Img fluid={props.avatars.avatar1.childImageSharp.fluid} /> */}
            <h3>
              <FormattedMessage id="story_1_author" />
            </h3>
            <h4>
              <FormattedMessage id="story_1_author_2" />
            </h4>
            <p>
              <FormattedMessage id="story_1" />
            </p>
          </div>
          <div className="col">
            {/* <Img fluid={props.avatars.avatar2.childImageSharp.fluid} /> */}
            <h3>
              <FormattedMessage id="story_2_author" />
            </h3>
            <h4>
              <FormattedMessage id="story_2_author_2" />
            </h4>
            <p>
              <FormattedMessage id="story_2" />
            </p>
          </div>
          <div className="col">
            {/* <Img fluid={props.avatars.avatar3.childImageSharp.fluid} /> */}
            <h3>
              <FormattedMessage id="story_3_author" />
            </h3>
            <h4>
              <FormattedMessage id="story_3_author_2" />
            </h4>
            <p>
              <FormattedMessage id="story_3" />
            </p>
          </div>
        </div>
        <div className="cols-3">
          <div className="col">
            {/* <Img fluid={props.avatars.avatar1.childImageSharp.fluid} /> */}
            <h3>
              <FormattedMessage id="story_4_author" />
            </h3>
            <h4>
              <FormattedMessage id="story_4_author_2" />
            </h4>
            <p>
              <FormattedMessage id="story_4" />
            </p>
          </div>
          <div className="col">
            {/* <Img fluid={props.avatars.avatar2.childImageSharp.fluid} /> */}
            <h3>
              <FormattedMessage id="story_5_author" />
            </h3>
            <h4>
              <FormattedMessage id="story_5_author_2" />
            </h4>
            <p>
              <FormattedMessage id="story_5" />
            </p>
          </div>
          <div className="col">
            {/* <Img fluid={props.avatars.avatar3.childImageSharp.fluid} /> */}
            <h3>
              <FormattedMessage id="story_6_author" />
            </h3>
            <h4>
              <FormattedMessage id="story_6_author_2" />
            </h4>
            <p>
              <FormattedMessage id="story_6" />
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
