import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react"
import Portal from "./Portal"
import { FormattedMessage } from "gatsby-plugin-intl"

const Modal = forwardRef((props, ref) => {
  const [display, setDisplay] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      openModal: () => handleOpen(),
      closeModal: () => handleClose(),
    }
  })

  useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) {
        handleClose()
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  const handleOpen = () => {
    setDisplay(true)
  }

  const handleClose = () => {
    setDisplay(false)
  }

  if (display) {
    return (
      <Portal>
        <div className="modal-backdrop"></div>
        <div className="modal-container" tabIndex="0">
          {props.children}
          <button className="btn modal-close" onClick={handleClose}>
            <FormattedMessage id="close" />
          </button>
        </div>
      </Portal>
    )
  }

  return null
})

export default Modal
