import React, { useRef, useState } from "react"
import Img from "gatsby-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Modal from "./modal"
import ModalVideo from "./modalvideo"
import { FormattedMessage } from "gatsby-plugin-intl"
import VideoPoster from "../images/poster.jpg"
import Promo from "../video/about.mp4"
import { Link } from "gatsby"

export default function Steps(props) {
  const modalRef2 = useRef()
  const modalRef3 = useRef()
  const modalRef4 = useRef()

  const [playing, setPlaying] = useState(false)

  return (
    <section className="steps" id="steps">
      <div className="container">
        <div className="steps-heading">
          <h2>
            <FormattedMessage id="steps_section_h2" />
          </h2>
          {/* This is tutorial button - hidden until tutorial video will be finished
          <button
            id="play"
            className="btn"
            className={playing ? "btn btn-play hidden" : "btn btn-play"}
            onClick={() => modalRef4.current.openModal()}
          >
            <FormattedMessage id="play_video" /> <span></span>
          </button> */}
        </div>
        <p className="text-size-3 mob-only">
          <FormattedMessage id="steps_1st_paragraph" />
        </p>
        <div className="cols-2 cols-2-first">
          <div className="col col-img">
            <Img fluid={props.imgs.phone1.childImageSharp.fluid} />
          </div>
          <div className="col">
            <p className="text-size-3">
              <FormattedMessage id="steps_1st_paragraph_copy" />
            </p>
            <div className="step">
              <h3>
                <FormattedMessage id="step_1_title" />
              </h3>
              <p>
                <FormattedMessage id="step_1_p_1" />
              </p>
              <div className="button_step">
                <Link to="/question" className="btn">
                  <FormattedMessage id="step_1_btn" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="cols-2 cols-2-second">
          <div className="col">
            <div className="step">
              <h3>
                <FormattedMessage id="step_2_title" />
              </h3>
              <p>
                <FormattedMessage id="step_2_p_2" />
              </p>
              <p>
                <FormattedMessage id="step_2_p_3" />
              </p>
              <div className="line"></div>
              <p>
                <FormattedMessage id="step_2_p_4" />
              </p>
              <button
                className="btn"
                onClick={() => modalRef2.current.openModal()}
              >
                <FormattedMessage id="step_2_btn" />
              </button>
            </div>
          </div>
          <div className="col col-img">
            <Img fluid={props.imgs.phone2.childImageSharp.fluid} />
          </div>
        </div>
        <div className="cols-2 cols-2-last">
          <div className="col col-img">
            <Img fluid={props.imgs.phone3.childImageSharp.fluid} />
          </div>
          <div className="col">
            <div className="step">
              <h3>
                <FormattedMessage id="step_3_title" />
              </h3>
              <p>
                <FormattedMessage id="step_3_p_1" />
              </p>
              <p>
                <FormattedMessage id="step_3_p_2" />
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal ref={modalRef1}>
        <h3>Jak sestavit správně dotaz?</h3>
        <div className="line"></div>
        <p>
          <FormattedMessage id="question" />
        </p>
        <div className="questionBox">
          <div className="question">
            <p>
              <FormattedMessage id="questionA" />
            </p>
          </div>
          <div className="answer">
            <p>
              <FormattedMessage id="questionAA" />
            </p>
          </div>
        </div>
        <div className="questionBox">
          <div className="question">
            <p>
              <FormattedMessage id="questionB" />
            </p>
          </div>
          <div className="answer">
            <p>
              <FormattedMessage id="questionBB" />
            </p>
          </div>
        </div>
        <div className="questionBox">
          <div className="question">
            <p>
              <FormattedMessage id="questionC" />
            </p>
          </div>
          <div className="answer">
            <p>
              <FormattedMessage id="questionCC" />
            </p>
          </div>
        </div>
        <div className="questionBox">
          <div className="question">
            <p>
              <FormattedMessage id="questionD" />
            </p>
          </div>
          <div className="answer">
            <p>
              <FormattedMessage id="questionDD" />
            </p>
          </div>
        </div>
        <div className="questionBox">
          <div className="question">
            <p>
              <FormattedMessage id="questionE" />
            </p>
          </div>
          <div className="answer">
            <p>
              <FormattedMessage id="questionEE" />
            </p>
          </div>
        </div>
        <div className="questionBox">
          <div className="question">
            <p>
              <FormattedMessage id="questionF" />
            </p>
          </div>
          <div className="answer">
            <p>
              <FormattedMessage id="questionFF" />
            </p>
          </div>
        </div>
      </Modal> */}

      <Modal ref={modalRef2}>
        <h3>
          <FormattedMessage id="step_2_btn" />
        </h3>
        <div className="line"></div>
        <div className="kouc_modal">
          <p>
            <FormattedMessage id="question2" />
            &nbsp;<a href="mailto:kouc@zivlovka.cz">kouc@zivlovka.cz</a>
          </p>
        </div>
      </Modal>

      {/* <Modal ref={modalRef3}>
        <h3>Jak najít živlového kouče?</h3>
        <div className="line"></div>
        <p>
          <FormattedMessage id="question3" />
        </p>
      </Modal> */}

      <ModalVideo ref={modalRef4} className="videoModal">
        <video
          id="video"
          src={Promo}
          poster={VideoPoster}
          preload="none"
          controls
        ></video>
      </ModalVideo>

      {/* <Modal show={isOpen} handleClose={hideModal}>
                <h3>Mám problém představit si obrazy</h3>
            </Modal> */}
    </section>
  )
}
