import React from "react"
import "../styles/styles.scss"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Method from "../components/method"
import Steps from "../components/steps"
import Stories from "../components/stories"

export default function Home(props) {
  return (
    <Layout>
      <Hero />
      <Method imgs={props.data} />
      <Steps imgs={props.data} />
      <Stories avatars={props.data} />
    </Layout>
  )
}

export const avatarImage = graphql`
  fragment avatarImage on File {
    childImageSharp {
      fluid(maxWidth: 260, maxHeight: 260) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
`

export const phoneImage = graphql`
  fragment phoneImage on File {
    childImageSharp {
      fluid(maxWidth: 383, maxHeight: 785) {
        ...GatsbyImageSharpFluid_withWebp
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
`

export const query = graphql`
  query {
    avatar1: file(relativePath: { eq: "avatar1.png" }) {
      ...avatarImage
    }
    avatar2: file(relativePath: { eq: "avatar2.png" }) {
      ...avatarImage
    }
    avatar3: file(relativePath: { eq: "avatar3.png" }) {
      ...avatarImage
    }
    phone1: file(relativePath: { eq: "01_phone@3x.png" }) {
      ...phoneImage
    }
    phone2: file(relativePath: { eq: "02_phone@3x.png" }) {
      ...phoneImage
    }
    phone3: file(relativePath: { eq: "03_phone@3x.png" }) {
      ...phoneImage
    }
    gameplan: file(relativePath: { eq: "Game-plane@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 430, maxHeight: 460) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    elements: file(relativePath: { eq: "elements.png" }) {
      childImageSharp {
        fluid(maxWidth: 650, maxHeight: 150) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
