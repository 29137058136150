import React, { useState } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import Header from "../components/header"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import VideoPoster from "../images/poster.jpg"
import Promo from "../video/about.mp4"

export default function Hero() {
  //   const intl = useIntl()

  const [playing, setPlaying] = useState(false)

  function playVideo() {
    var vid = document.getElementById("video")
    setPlaying(true)
    vid.play()
    vid.setAttribute("controls", true)
    if (window.innerWidth < 768) {
      if (vid.requestFullscreen) {
        vid.requestFullscreen()
      } else if (vid.mozRequestFullScreen) {
        vid.mozRequestFullScreen()
      } else if (vid.webkitRequestFullscreen) {
        vid.webkitRequestFullscreen()
      } else if (vid.msRequestFullscreen) {
        vid.msRequestFullscreen()
      }
    }
  }

  return (
    <section className="hero" id="hero">
      <Header />
      <div className="container">
        <div className="hero-content">
          <hgroup>
            <h1>
              <FormattedMessage
                id="title"
                defaultMessage="Hra živlových bran"
              />
            </h1>
            <h3>
              <span>
                <FormattedMessage
                  id="hero_game_title"
                  defaultMessage="Hra živlových bran"
                />
              </span>
            </h3>
          </hgroup>
          <div className="hero-content-cols">
            <div className="hero-content-left">
              <h2>
                <FormattedMessage
                  id="hero_content_left_h2"
                  defaultMessage="Ovládněte své emoce pomocí živlů"
                />
              </h2>
              <p>
                <FormattedMessage
                  id="hero_content_paragraph"
                  defaultMessage="Jednejte podle svého pravého Já a objevte sílu vlastní intuice."
                />
              </p>
            </div>
            <div className="hero-content-right">
              <div className="video-wrapper">
                <video
                  id="video"
                  src={Promo}
                  poster={VideoPoster}
                  preload="none"
                ></video>
                {playing}
                <button
                  type="button"
                  id="play"
                  className={playing ? "btn btn-play hidden" : "btn btn-play"}
                  onClick={playVideo}
                >
                  <FormattedMessage
                    id="play_video"
                    defaultMessage="přehrát video"
                  />{" "}
                  <span></span>
                </button>
              </div>
            </div>
          </div>
          <div className="scroll-down">
            <AnchorLink to="/#method">
              <span>Scroll</span>
            </AnchorLink>
          </div>
        </div>
      </div>
      <div className="linearGrad"></div>
    </section>
  )
}
