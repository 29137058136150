import React from "react"
import Tabs from "./Tabs"
import Img from "gatsby-image"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

export default function Method(props) {
  const intl = useIntl()

  return (
    <section id="method">
      <div className="container">
        <h2>
          <FormattedMessage id="method_section_h2" />
        </h2>

        <Tabs>
          <div
            label={intl.formatMessage({
              id: "method_tab_1_title",
            })}
          >
            <div className="cols-2">
              <div className="col">
                <p className="text-size-3">
                  <FormattedMessage id="method_tab_1_content_p_1" />
                </p>
                <p>
                  <FormattedMessage id="method_tab_1_content_1" />
                </p>
                <p>
                  <FormattedMessage id="method_tab_1_content_2" />
                </p>
                <div className="elements">
                  <Img fluid={props.imgs.elements.childImageSharp.fluid} />
                </div>
              </div>
              <div className="col col-img">
                <Img fluid={props.imgs.gameplan.childImageSharp.fluid} />
              </div>
            </div>
          </div>
          <div
            label={intl.formatMessage({
              id: "method_tab_2_title",
            })}
          >
            <div className="cols-2">
              <div className="col">
                <p className="text-size-3">
                  <FormattedMessage id="method_tab_2_content_p_1" />
                </p>
                <p>
                  <FormattedMessage id="method_tab_2_content" />
                </p>
                <p>
                  <FormattedMessage id="method_tab_2_content_2" />
                </p>
                <p>
                  <FormattedMessage id="method_tab_2_content_3" />
                </p>
              </div>
              <div className="col col-img">
                <Img fluid={props.imgs.gameplan.childImageSharp.fluid} />
              </div>
            </div>
          </div>
          <div
            label={intl.formatMessage({
              id: "method_tab_3_title",
            })}
          >
            <div className="cols-2">
              <div className="col">
                <p className="text-size-3">
                  <FormattedMessage id="method_tab_3_content_p_1" />
                </p>
                <p>
                  <FormattedMessage id="method_tab_3_content_p_2" />
                </p>
                <ul>
                  <li>
                    <FormattedMessage id="method_tab_3_content_li_1" />
                  </li>
                  <li>
                    <FormattedMessage id="method_tab_3_content_li_2" />
                  </li>
                  <li>
                    <FormattedMessage id="method_tab_3_content_li_3" />
                  </li>
                  <li>
                    <FormattedMessage id="method_tab_3_content_li_4" />
                  </li>
                  <li>
                    <FormattedMessage id="method_tab_3_content_li_5" />
                  </li>
                </ul>
              </div>
              <div className="col col-img">
                <Img fluid={props.imgs.gameplan.childImageSharp.fluid} />
              </div>
            </div>
          </div>
          <div
            label={intl.formatMessage({
              id: "method_tab_4_title",
            })}
          >
            <div className="cols-2">
              <div className="col">
                <p className="text-size-3">
                  <FormattedMessage id="method_tab_4_content_p_1" />
                </p>
                <p>
                  <FormattedMessage id="method_tab_4_content_1" />
                </p>
                <p>
                  <FormattedMessage id="method_tab_4_content_2" />
                </p>
              </div>
              <div className="col col-img">
                <Img fluid={props.imgs.gameplan.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </Tabs>
      </div>
    </section>
  )
}
