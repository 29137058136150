import React from "react"

export default function Tab(props) {
  const { activeTab, label } = props
  let activeClass = ""

  if (activeTab === label) {
    activeClass += " active"
  }

  function onClick() {
    const { label, onClick } = props
    onClick(label)
  }

  return (
    <li className={activeClass} onClick={onClick}>
      <span>{label}</span>
    </li>
  )
}
